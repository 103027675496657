<template>
  <div class="questionnaire">
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="问卷名称">
          <el-input v-model="queryForm.mold" placeholder="请填写问卷名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker type="datetimerange" v-model="times" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="状态">
          <el-select
            v-model="queryForm.display"
            placeholder="请选择状态"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in displayArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="">添加问卷</el-button>
        </el-form-item>
      </el-form>
      <div style="font-size:16px;font-weight:bold;margin-bottom:20px;">问卷链接：<a href="https://www.kt.qiaxueedu.com/app/question/#/" style="font-size:16px;font-weight:bold;">https://www.kt.qiaxueedu.com/app/question/#/</a></div>
      <!-- 问卷主题表格 -->
      <el-table
        :data="questionnaireList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column label="问卷名" prop="title" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="create_time" align="center"></el-table-column>
        <el-table-column label="失效时间" prop="end_time" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.end_time == null">--</div>
          </template>
        </el-table-column>
        <el-table-column label="参与人数" prop="total" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.total == 0">--</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="状态" prop="state_tit" align="center">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.display == 1"
              @click="editState(scope.row.id, 0)"
            >
              启用
            </el-tag>
            <el-tag type="danger" v-else @click="editState(scope.row.id, 1)"
              >禁用
            </el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <div class="operation_group">
              <div class="main" v-for="(item,index) in operationList" :key="index"  @click="Fn(item,scope.$index)">
                <img :src="item.img" alt="图片加载失败">
                <div>{{item.title}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <div class="pagination">
        <span></span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryForm.page"
          :page-sizes="[3, 5, 8, 10]"
          :page-size="queryForm.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryForm.total"
          algin:="center"
        ></el-pagination>
      </div>

      <!-- 数据弹窗 -->
      <el-dialog class="dialog"  :visible.sync="addDialog" :center='true' width="80%" @close="closed" :close-on-click-modal='false'>
        <div class="tit">
          <div @click="DataReport">数据报表</div>
          <div @click="DataDetails">数据详情</div>
          <div :style="{left:lineNum+'px'}" class="line"></div>
        </div>
        <!-- 数据报表页 -->
        <div class="report" v-if="!recordIndex">
          <div class="head">
            <div class="headname">标题名称</div>
            <div>总参与人数:{{join}}</div>
          </div>
          <div class="main">
            <div class="list" v-for="item in optionList">
              <div class="titleinfo">
                <span class="subject">{{item.subject}}</span>
                <span class="type">{{computed_state(item.type)}}</span>
              </div>
              <div class="peoplenum">
                <span>作答人数：{{item.title_count}}</span>
                <!-- <span>完成率：{{item.completionRate}}</span> -->
                <span>完成率：{{item.title_count/join*100 | filterPrice}}%</span>
              </div>
              <div v-if="item.A">
                <div class="progress" v-for="items in item.peopleList">
                    <span class="option">{{Object.keys(items)+''}}</span>
                    <el-progress :text-inside="true" :stroke-width="12" :percentage="Number((Number(Object.values(items)+'')/item.title_count*100).toFixed(2))"></el-progress>
                    <span class="chancenum">{{Object.values(items)+''}}人选择</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 数据详情页 -->
        <div class="details" v-else>
          <el-table  
            :data="tableDataCoputed"
            height="500"
            border
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            style="width: 100%">
            <el-table-column prop="id" label="编号" width="80"></el-table-column>
            <el-table-column prop="nickname" label="答题人" width="120"></el-table-column>
            <el-table-column v-for="(item2,index) in subjectList" :key="index" :label="item2.subject" width="180">
              <template slot-scope="scope">
                <!-- {{scope.$index+1}} -->
                {{tableDataCoputed[scope.$index].answerList[index]}}
              </template>
            </el-table-column>
            <el-table-column prop="start_time" label="开始时间" width="160"></el-table-column>
            <el-table-column prop="end_time" label="结束时间" width="160"></el-table-column>
            <el-table-column prop="answer_time" label="答题时间" width="160"></el-table-column>
            <!-- <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)">查看</el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <!-- 数据报表分页区域 -->
        <div class="pagination" v-if="!recordIndex">
          <span></span>
          <el-pagination
            @size-change="detailhandleSizeChange"
            @current-change="detailhandleCurrentChange"
            :current-page="reportForm.page"
            
            :page-size="reportForm.limit"
            layout="total,prev, pager, next, jumper"
            :total="reportForm.total"
            algin:="center"
          ></el-pagination>
        </div>
        <!-- 数据详情分页区域 -->
        <div class="pagination" v-else>
          <span></span>
          <el-pagination
            @size-change="detailhandleSizeChange"
            @current-change="detailhandleCurrentChange"
            :current-page="detailForm.page"
            :page-size="detailForm.limit"
            layout="total, prev, pager, next, jumper"
            :total="detailForm.total"
            algin:="center"
          ></el-pagination>
        </div>
    </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {getansThemeList,getansList,getansTitleList,getansStatistics,getCopyQuestionnaire} from '@/api/questionnaire'
import { warningDialog } from "@/utils/dialog";
export default {
  components: {},
  data() {
    return {
      lineNum:84,
      //问卷分页数组
      queryForm: {
        mold: null,
        display: null,
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
        total: 0
      },
      // 数据详情分页
      detailForm:{
        mold: null,
        display: null,
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
        total:''
      },
      // 数据报表分页
      reportForm:{
        mold: null,
        display: null,
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
        total:''
      },
      //问卷主题数据源
      questionnaireList: [],
      // 状态数组
      displayArr: [
        { key: 0, value: "未开始" },
        { key: 1, value: "进行中" },
        { key: 2, value: "已结束" }
      ],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        mold: "",
        name: "",
        desc: "",
        avatar: "",
        show_app: "",
        display: null,
        content: "",
      },
      thumbSrc: "",
      appThumbSrc: "",
      //操作栏按钮
      operationList:[{
        img:require('../../../assets/img/questionnaire_img/release.png'),
        title:'数据',
        click:"toAdd"
      },
      {
        img:require('../../../assets/img/questionnaire_img/record.png'),
        title:'复制',
        click:"toCopy"
      },
      {
        img:require('../../../assets/img/questionnaire_img/del.png'),
        title:'删除'
      }],
      //用于控制报表和详情的模块显示
      recordIndex:0,
      //报表数据
      reportList:[{
        // tit:'是否满意教学课程',
        // type:'【单选题】',
        // peopleNum:450,
        // completionRate:'80%'
      }],
      join:null,
      options:{},
      peopleNum:[],
      //详情数据
       tableData: [],
      //题目列表
      subjectList:[],
      //控制数据表单显示
      tableDataState:0
    };
  },
  computed:{
    //做数据过滤
    computed_state(){
      return e => {
        if (e === 4) {
          return "【单选题】";
        } else if (e === 6) {
          return "【多选题】";
        } else if (e === 1) {
          return "【填空题】";
        }else if (e === 2) {
          return "【填空题】";
        }
      };
    },
    //详情数据
    tableDataCoputed(){
      return  this.tableData
    },
    //数据报表的返回数据处理
    optionList(){
      let arr = []
      this.reportList.map(item=>{
        let option = eval('(' + item.options + ')')
        // console.log(option)
        let peopleList = []
        for(var key in option){
          if(key && item.hasOwnProperty(key)){
            peopleList.push({[option[key]]:item[key]})
          }
        } 
        arr.push({...item,peopleList})
      })
      // console.log(arr)
      return arr 
    }
  },
  watch:{},
  created() {
    this.getquestionnaireData()
    this.getgetansData()
  },
  methods: {
    init(){},
    //获取问卷主题列表
    getquestionnaireData(){
      console.log(this.queryForm,"queryForm");
      getansThemeList(this.queryForm).then(res=>{
        console.log('res',res);
        if(!this.queryForm.total){
           this.queryForm.total=res.data.total
        }
        this.questionnaireList=res.data.data
        let date=new Date()
        let nowDate=date.getTime()
        if(this.questionnaireList[0].end_time){
          this.questionnaireList.forEach(item => {
            if(nowDate>new Date(item.end_time).getTime()){
              item.state=0
              item.state_tit='未开始'
            }
            else if(nowDate=new Date(item.end_time).getTime()){
              item.state=1
              item.state_tit='进行中'
            }else{
              item.state=2
              item.state_tit='已结束'
            }
          });
        }
      })
    },
    //问卷答题列表
    getgetansData(id){
      this.detailForm.theme_id=id
      //问卷题目列表
      getansTitleList({theme_id:id}).then(res=>{
        console.log(res)
        this.subjectList=res.data.data.map(val=>{
          val.options=JSON.parse(val.options)
          return val
        })
        if(this.subjectList[0].id){
          //答案列表（数据详情）
          this.getAnsData()
        }
      })
    },
    //获取数据详情
    getAnsData(){
      getansList(this.detailForm).then(res=>{
        // console.log('res',res);
        if(!this.detailForm.total){
          this.detailForm.total=res.data.total
        }
        this.detailForm.total=res.data.total
        this.tableData=res.data.data.map((val)=>{
          let answerList=[]
          //计算答题时间
          val.answer_time=this.getDatePoor(val.start_time,val.end_time)
          // console.log('val',val);
          this.subjectList.forEach(val2=>{
            val.detail.forEach(val3=>{
              if(val3.title_id===val2.id){
                answerList.push(val3.answer+(val2.options[val3.answer]?'.'+val2.options[val3.answer]:''))
              }
            })
            val.answerList=answerList
          })
          return val
        })
      })
    },
    //获取数据报表
    getAnsReport(theme_id){
      getansStatistics({theme_id}).then(res=>{
        // console.log(res,1111)
        this.reportList = res.data.options
        this.join = res.data.join
        this.reportForm.total = res.data.options.length
        // for (let i = 0;i < this.reportList.length ; i ++ ){
        //   let data = this.reportList[i]
        //   let option = JSON.parse(data.options)
        //   let arr = []
        //   for(var key in option){
        //     if(key && data.hasOwnProperty(key)){
        //       arr.push(data[key])
        //       data.peopleNum = arr
        //       data.option = option
        //     }
        //   } 
        // }
      })
    },
    //计算两个日期直接的时间差
    getDatePoor(start_time,end_time) {
      let startTime=new Date(start_time).getTime()
      let endTime=new Date(end_time).getTime()
      let drr = Math.abs(endTime - startTime);
      console.log();
     var day = parseInt(drr / (24 * 60 * 60 * 1000));
      var hours = parseInt(drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
      var minutes = parseInt(drr % (60 * 60 * 1000) / (60 * 1000));
      var seconds = parseInt(drr % (60 * 1000) / 1000);
      var res = (day*24+hours).toString().padStart(2,'0') + ":" + minutes.toString().padStart(2,'0') + ":" + seconds.toString().padStart(2,'0');
      return res
    },
    //操作按钮点击
    Fn(item,index){
      if(item.click==='toAdd'){
        this.toAdd(this.questionnaireList[index].id)
      }else if(item.click==='toCopy'){
        this.toCopy(this.questionnaireList[index].id)
      }
    },
    //确认是否复制问卷弹窗
    toCopy(theme_id){
      warningDialog("确定需要复制？").then(()=>{
        this.copyQuestionnaire(theme_id)
      })
    },
    //复制问卷
    copyQuestionnaire(theme_id){
      getCopyQuestionnaire({theme_id}).then(res=>{
        console.log(res,"copy")
        this.getquestionnaireData()
      })
    },
    // 添加弹窗
    toAdd(id) {
      this.addDialog = true;
      this.getgetansData(id)
      this.getAnsReport(id)
    },

    // 数据弹窗关闭事件
    closed() {
    
    },
    //数据按钮 数据报告功能
    DataReport(){
      this.lineNum=84
      this.recordIndex=0
    },
    //数据按钮 数据详情功能
    DataDetails(){
      this.lineNum=226
      this.recordIndex=1
    },
     //  查询
    toQuery() {
      console.log('toQuery');
      if (this.queryForm.display === "") {
        this.queryForm.display = null;
      }
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getTeacherList();
    },
    //pageSize 改变时会触发
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getquestionnaireData();
    },
    //currentPage 改变时会触发
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getquestionnaireData();
    },
    //pageSize 改变时会触发 数据详情
    detailhandleSizeChange(size) {
      this.detailForm.limit=size
      this.getAnsData();
    },
    //currentPage 改变时会触发 数据详情
    detailhandleCurrentChange(page) {
      this.detailForm.page = page;
      this.getAnsData();
    },
  },
};
</script>

<style scoped lang="scss">
.questionnaire{
  .generate {
    float: right;
  }
  .el-tag {
    cursor: pointer;
  }
  ::v-deep .ql-editor {
    min-height: 400px;
  }
  .thumb_box {
    width: 100%;
    height: 100%;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .operation_group{
    display: flex;
    align-items: center;
    justify-content: space-around;
    .main{
      display: flex;
      align-items: center;
      img{
        width: 14px;
        height: 14px;
        padding-right: 8px;
      }
      div{
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 16px; 
        color: #323232;
        opacity: 1;
      }
    }
    .main :hover{
      cursor:pointer
    }
  }
  ::v-deep .el-dialog__body {
    padding: 25px 0 25px;
  }
  ::v-deep .el-dialog__headerbtn{  
    z-index: 99;  //弹窗里的×点不到了升高层级
  }
  .dialog{
    .tit{
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      padding-bottom: 25px;
      color: #37A58C;
      margin: -38px 0 0 0;
      opacity: 1;
      // border-bottom: 1px solid #CCCCCC;
      transition: all 0.5s;
      div:hover{
        cursor:pointer
      }
      div:nth-child(1) {
        margin:0 91px 0 70px;
      }
      .line{
        position: absolute;
        top: 23px;
        width: 16px;
        height: 2px;
        background: #37A58C;
        opacity: 1;
        border-radius: 1px;
      }
    }
    // 数据报表页
    .report{
      height: 520px;
      overflow-y: scroll;
      margin: 0 20px 0 20px;
      .head{
        display: flex;
        align-items: center;
        border-top: 1px solid #CCCCCC;
        border-bottom: 1px solid #CCCCCC;
        height: 65px;
        line-height: 65px;
        div:nth-child(1) {
          margin:0 298px 0 70px;
        }
        .headname{
          font-weight: bold;
          color: #37A58C;
        }
      }
      .main{
        .list{
          padding-left: 70px;
          border-bottom: 1px solid #CCCCCC;
          .titleinfo{
            margin: 29px 0 0 0;
            .subject{
              font-weight: bold;
              padding-right: 10px;
            }
            .type{
              color: #CCCCCC;
            }
          }
          .peoplenum{
            margin: 20px 0 38px 0;
            span:nth-child(1) {
              margin: 0 120px 0 0;
            }
            // margin-bottom: 38px;
          }
          .titname{
            font-weight: bold;
            margin-bottom: 38px;
          }
          .progress{
            width: 300px;
            margin-bottom: 22px;
            display: flex;
            .el-progress{
              width: 100%;
            }
            .option{
              display: inline-block;
              width: 60px;
            }
            .chancenum{
              display: inline-block;
              width: 100px;
              padding-left: 20px;
            }
          }
        }
      }
    }
    // 数据详情页
    .details{
      padding: 20px 20px 0 20px;
    }
  }
}

</style>
